let PetService = function(
    $filter
) {
    let trans = (key, prefix = 'purchasing.') => {
        return $filter('translate')(prefix + key);
    };

    let checkIsInt = function (value) {
        return parseInt(value) == Number(value);
    }
    
    return new (function() {
        this.validatePetNr = function($ctrl) {
            let isValidCat = true, isValidDog = true;
    
            if ($ctrl.showCats) {
                isValidCat = $ctrl.form.values.quantity_cat != null && 
                $ctrl.form.values.quantity_cat &&
                checkIsInt($ctrl.form.values.quantity_cat)
            }
    
            if ($ctrl.showDogs) {
                isValidDog = $ctrl.form.values.quantity_dog != null && 
                $ctrl.form.values.quantity_dog &&
                checkIsInt($ctrl.form.values.quantity_dog)
            }
    
            return isValidCat && isValidDog;
        }
    
        this.validateByParam = function($ctrl, paramName, allowZero = false) {
            let isValid = true;
    
            $ctrl.form.values.cats.forEach(function(cat) {
                if (typeof cat[paramName] == 'undefined' || 
                    cat[paramName] == null || 
                    (!allowZero && !cat[paramName])
                ) {
                    isValid = false;
                }
            });
            
            $ctrl.form.values.dogs.forEach(function(dog) {
                if (typeof dog[paramName] == 'undefined' || 
                    dog[paramName] == null || 
                    (!allowZero && !dog[paramName])
                ) {
                    isValid = false;
                }
            });
    
            return isValid;
        }

        this.validateAge = function ($ctrl) {
            let isValid = this.validateByParam($ctrl, 'age', true);

            if (!isValid) {
                $ctrl.err = trans('errors_no_age');
            } else {
                $ctrl.form.values.cats.forEach(function(cat) {
                    if (parseInt(cat.age) == 0) {
                        isValid = false;
                        $ctrl.err = trans('errors_low_age');
                    }
                });
                
                $ctrl.form.values.dogs.forEach(function(dog) {
                    if (parseInt(dog.age) == 0) {
                        isValid = false;
                        $ctrl.err = trans('errors_low_age');
                    }
                });
            }

            return isValid;
        }

        this.validateWeight = function ($ctrl) {
            let isValid = this.validateByParam($ctrl, 'weight');
            $ctrl.err = trans('errors_no_weight');

            return isValid;
        }

        this.validateStep = function($ctrl) {
            let isValid = false;
    
            switch($ctrl.step) {
                case 1: 
                    isValid = $ctrl.form.values.pet_type != null; 
                    $ctrl.err = trans('errors_no_pet_type');
                    break;
                case 2: 
                    isValid = this.validatePetNr($ctrl); 
                    $ctrl.err = trans('errors_no_quantity');
                    break;
                case 3: 
                    isValid = this.validateByParam($ctrl, 'name'); 
                    $ctrl.err = trans('errors_no_pet_name');
                    break;
                case 4: 
                    isValid = this.validateAge($ctrl);
                    break;
                case 5: 
                    isValid = this.validateWeight($ctrl);
                    break;
                case 6: 
                    isValid = this.validateByParam($ctrl, 'castrated'); 
                    $ctrl.err = trans('errors_no_castrated');
                    break;
                case 7: 
                    isValid = this.validateByParam($ctrl, 'activity'); 
                    $ctrl.err = trans('errors_no_activity');
                    break;
                case 8: 
                    isValid = this.validateByParam($ctrl, 'preferred_taste'); 
                    $ctrl.err = trans('errors_no_taste');
                    break;
            }
    
            $ctrl.hasErrors = isValid;
    
            return isValid;
        }
    });
};

module.exports = [
    '$filter',
    PetService
];