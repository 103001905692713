let i18nTranslations = {};
const app = angular.module('siidaApp', ['pascalprecht.translate', 'ngCookies']);

const appConfigs = {
    api_url: 'https://siida.rminds.dev/api', //'http://www.siida.loc/api' 'https://siida.rminds.dev/api'
    i18nTranslations: i18nTranslations
};

app.constant('appConfigs', appConfigs);

// Components
app.component('purchaseComponent', require('./components/PurchaseComponent'));
app.component('productComponent', require('./components/ProductComponent'));

// Services
app.service('FormBuilderService', require('./services/FormBuilderService'));
app.service('PurchaseService', require('./services/PurchaseService'));
app.service('ProductService', require('./services/ProductService'));
app.service('PetService', require('./services/PetService'));
app.service('ValidationService', require('./services/ValidationService'));

// Providers
app.provider('ApiRequest', require('./providers/ApiRequestProvider'));

// Filters
app.filter('pretty_json', require('./filters/PrettyJsonFilter'));

// Config
app.config(require('./config/api-service'));
app.config(require('./config/i18n'));

app.config(['$compileProvider', function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|callto|data:img\/png;base64|javascript):/);
}])

$.get('/api/public/translations?per_page=100000').done((res) => {
    res.data.map(transItem => {
        if (typeof i18nTranslations[transItem.locale] != 'object') {
            i18nTranslations[transItem.locale] = {};
        }

        if (typeof i18nTranslations[transItem.locale][transItem.group] != 'object') {
            i18nTranslations[transItem.locale][transItem.group] = {};
        }

        i18nTranslations[transItem.locale][transItem.group][transItem.item] = transItem.text;
    });

    // Bootstrap the app
    angular.bootstrap(document.querySelector('html'), ['siidaApp']);
});