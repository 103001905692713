let PurchaseComponent = function(
    FormBuilderService,
    PurchaseService,
    PetService,
    ValidationService,
    $filter
) {
    let $ctrl = this;

    let trans = (key, prefix = 'purchasing.') => {
        return $filter('translate')(prefix + key);
    };

    $ctrl.hasErrors = false;
    $ctrl.skipCastrated = false;

    $ctrl.$onInit = () => {
        for(let prop in PetService) {
            $ctrl[prop] = PetService[prop]; 
        }

        $ctrl.setOptions();

        $ctrl.step = 1;

        PurchaseService.getData().then(res => {
            let data = PurchaseService.apiResourceToForm(res);

            if (data.cats.length || data.dogs.length) {
                if (data.pet_type == 'dog') {
                    if (!$ctrl.skipCastrated) {
                        $ctrl.skipCastrated = true;
                        $ctrl.steps.splice(5, 1);
                    }
    
                    $ctrl.showDogs = true;
                    $ctrl.showCats = false;

                    $ctrl.step = $ctrl.steps.length + 1;
                } else {    
                    $ctrl.showCats = true;
                    $ctrl.showDogs = false;
                    if (data.pet_type == 'both') {
                        $ctrl.showDogs = true;
                    }

                    $ctrl.step = $ctrl.steps.length;
                }
            }

            $ctrl.form = FormBuilderService.build(data, (form) => {
                if ($ctrl.validateStep()) {
                    if ($ctrl.step < $ctrl.countSteps) {
                        $ctrl.goNext();
                        return;
                    }
    
                    $ctrl.cleanErrors();
                    
                    form.lock();
    
                    PurchaseService.calculate(
                        form.values
                    ).then(res => {
                        $ctrl.submitError = null;
                        document.location.href = res.data.redirect_url;
                    }, res => {
                        $ctrl.submitError = trans('errors_try_later');
                    });
                }
            });
        });
    };

    $ctrl.goPrev = function($event) {
        if ($ctrl.step == 7 && $ctrl.skipCastrated) {
            return $ctrl.step -= 2;
        } else {
            --$ctrl.step;
        }
        
        $ctrl.cleanErrors();
    };

    $ctrl.goNext = function($event) {
        if ($ctrl.validateStep() && $ctrl.step < $ctrl.countSteps) {
            let values = $ctrl.form.values;

            // Prepare pet data defaults
            if ($ctrl.step == 1) {
                if (values.pet_type == 'cat') {
                    if (!values.cats || !values.cats.length) {
                        values.quantity_cat = 1;
                        values.cats = [$ctrl.getPetInstance('cat')];
                    }

                    values.quantity_dog = 0;
                    values.dogs = [];
                    
                    $ctrl.showCats = true;
                    $ctrl.showDogs = false;

                    $ctrl.skipCastrated = false;
                    $ctrl.steps = $ctrl.initialSteps;
                } else if (values.pet_type == 'dog') {
                    if (!values.dogs || !values.dogs.length) {
                        values.quantity_dog = 1;
                        values.dogs = [$ctrl.getPetInstance('dog')];
                    }

                    values.quantity_cat = 0;
                    values.cats = [];
                    
                    $ctrl.showCats = false;
                    $ctrl.showDogs = true;

                    if (!$ctrl.skipCastrated) {
                        $ctrl.skipCastrated = true;
                        $ctrl.steps.splice(5, 1);
                    }
                } else {
                    if (!values.dogs || !values.dogs.length) {
                        values.quantity_dog = 1;
                        values.dogs = [$ctrl.getPetInstance('dog')];
                    }
                    if (!values.cats || !values.cats.length) {
                        values.quantity_cat = 1;
                        values.cats = [$ctrl.getPetInstance('cat')];
                    }
                    
                    $ctrl.showCats = true;
                    $ctrl.showDogs = true;

                    $ctrl.skipCastrated = false;
                    $ctrl.steps = $ctrl.initialSteps;
                }
            } else if ($ctrl.step == 2) {
                $ctrl.changePetNr();
            }

            if ($ctrl.skipCastrated && $ctrl.step == 5) {
                $ctrl.step += 2;
            } else {
                ++$ctrl.step;
            }

            $ctrl.cleanErrors();
        } 
    };

    $ctrl.validateStep = function() {
        return ValidationService.validateStep($ctrl);
    }

    $ctrl.cleanErrors = function() {
        $ctrl.err = null;
        $ctrl.hasErrors = false;
    }

    $ctrl.isActiveStep = function(step) {
        return $ctrl.step == step;
    }

    $ctrl.isActiveMenuOption = function(step) {
        if (!$ctrl.skipCastrated && $ctrl.step == step) {
            return true;
        }

        if ($ctrl.skipCastrated) {
            if ($ctrl.step > 5) {
                return $ctrl.step == step + 1;
            } else {
                return $ctrl.step == step;
            }
        }

        return false;
    }

    $ctrl.$onDestroy = function() { };
};

module.exports = {
    controller: [
        'FormBuilderService',
        'PurchaseService',
        'PetService',
        'ValidationService',
        '$filter',
        PurchaseComponent
    ],
    templateUrl: () => {
        return '/assets/tpl/pages/purchase.html';
    }
};