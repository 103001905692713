require('./angular-1/angular-app');

$.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function() {
// Get the modal
    var modal = $("#contact-us");

// Get the button that opens the modal
    var btn = $(".show-contact-form");

// Get the <span> element that closes the modal
    var close = $(".modal-close");

// Submit form

    var form = modal.find("form");

    var contactFormInProcess = false,
        newsletterFormInProcess = false;

    // contact form
    $(document).on('submit', '.contact-form',function (e) {
        e.preventDefault();

        if (contactFormInProcess) {
            return;
        }

        contactFormInProcess = true;

        var form = $(this);

        $.post(form.attr('action'), form.serialize())
            .done(function (data) {
                if(data.result === 'OK'){
                    contactFormInProcess = false;

                    form.find('input[type="text"]').val('');
                    form.find('input[type="email"]').val('');
                    form.find('textarea').val('');

                    $.each(form.find('.form-group'), function (key, formGroup) {
                        let formControl = $(formGroup).find('.form-control');
                        $(formGroup).removeClass('has-error');
                        formControl.attr("placeholder", formControl.data('placeholder'))
                    });

                    form.hide();
                    form.closest(".modal-content").find(".modal-title").hide();
                    form.closest(".modal-content").find(".block-info").addClass("show").find(".block-info-title").text(data.text)
                }
            }).fail(function (data) {

            contactFormInProcess = false;

            if (data.status === 422) {

                var errors = data.responseJSON.errors;

                $.each(form.find('.form-group'), function (key, formGroup) {
                    let formControl = $(formGroup).find('.form-control');
                    $(formGroup).removeClass('has-error');
                    formControl.attr("placeholder", formControl.data('placeholder'))
                });

                $.each(errors, function (key, value) {
                    form.find("[name='" + key + "']").parent('.form-group').addClass('has-error');
                    form.find("[name='" + key + "']").attr('placeholder', value.toString());
                });
            }
        });
    });

    // contact form
    $(document).on('submit', '#newsletter-add',function (e) {
        e.preventDefault();

        if (newsletterFormInProcess) {
            return;
        }

        newsletterFormInProcess = true;

        var form = $(this);

        $.post(form.attr('action'), form.serialize())
            .done(function (data) {
                newsletterFormInProcess = false;

                form.find('input[type="text"]').val('');
                form.find('input[type="email"]').val('');

                form.find(".alert-info").addClass("show").text(data.message);
            }).fail(function (data) {

            newsletterFormInProcess = false;

            if (data.status === 422) {

                var errors = data.responseJSON.errors;

                $.each(errors, function (key, value) {
                    form.find("[name='" + key + "']").parent('.input-wrapper').addClass('has-error');
                    //form.find("[name='" + key + "']").attr('placeholder', value.toString());
                });
            }
        });
    });

// When the user clicks on utside the modal-content
    $(document).mouseup(function (e){

        var container = $(modal).find('.modal-content');

        if (!container.is(e.target) && container.has(e.target).length === 0){

            modal.removeClass('show');
            modal.fadeOut();

        }
    });


// When the user clicks on the button, open the modal 
    $(btn).on('click', function (e) {
        e.preventDefault();

        $(form).show();

        $.each(form.find('.form-group'), function (key, formGroup) {
            let formControl = $(formGroup).find('.form-control');
            $(formGroup).removeClass('has-error');
            formControl.attr("placeholder", formControl.data('placeholder'));
            formControl.val("");
        });

        $(form).closest(".modal-content").find(".modal-title").show();
        $(form).closest(".modal-content").find(".block-info").removeClass("show").find(".block-info-title").text("")

        modal.fadeIn();
    });

// When the user clicks on <span> (x), close the modal
    close.on('click', function (e) {
        e.preventDefault();

        modal.fadeOut();
    });

    modal.find(".block-info-close").on('click', function (e) {
        e.preventDefault();

        modal.fadeOut();
    });

    // var slideControls = $('.block-slider .slick-custom-nav');
    var slideControls = $('.block-slider .slider-wrapper-nav .slider-nav-control');
    //slick slider init

    $('.main-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true
    });

    var submitProductData = function(type, product_id, count) {
        $.ajax({
            'method': 'POST',
            'url': 'subscriptions/' + type,
            'data': {
                'product_id': product_id,
                'count': count
            }
        }).done(function(response) {
            //console.log(response);
        }).fail(function(response) {
            //console.log(response);
        });
    }

    //add input btn
    $('.in-btn.minus').click(function () {
        var $input = $(this).parent().find('input[name="count"]');
        var count = parseInt($input.val()) - 1;
        
        count = count < 0 ? 0 : count;
        $input.val(count);
        if (count == 0) {
            $(this).removeClass('start');
        }

        $input.change();

        if ($(this).hasClass('products')) {
            var type = $(this).hasClass('regular') ? 'add-regular': 'add-singular';
            var product_id = parseInt($(this).closest('.input-wrapper')
                .find('[name="product_id"]').val()); 

            submitProductData(type, product_id, count);
        }

        return false;
    });

    $('.in-btn.plus').click(function () {
        var $input = $(this).parent().find('input[name="count"]');
        var count = parseInt($input.val()) + 1;

        $input.val(count);
        $input.change();

        $(this).addClass('start');

        if ($(this).hasClass('products')) {
            var type = $(this).hasClass('regular') ? 'add-regular': 'add-singular';
            var product_id = parseInt($(this).closest('.input-wrapper')
                .find('[name="product_id"]').val()); 

            submitProductData(type, product_id, count);
        }

        return false;
    });

    $(document).ready(function(){
        //handleOrderSteps();
    });

    $(window).resize(function() {
        //handleOrderSteps();
    });

    // $('#pur-form .step-choise .choise').on('click', function(){
    //     if($(this).hasClass('active')) {
    //         $(this).removeClass('active');
    //     } else {
    //         $(this).addClass('active').siblings().removeClass('active');
    //     }
    // });

    //menu
    $('.burger').on('click', function(){
        $('body').toggleClass('op-menu');
        $(this).toggleClass('active');
        $('.menu-box').toggleClass('active');
    });

    $(window).on('load, resize', function(){
        $('body').removeClass('op-menu');
        $('.burger').removeClass('active');
        $('.menu-box').removeClass('active');
    });

    // sign in/up
    $('a').on('click', function (e) {
        if ($(this).hasClass('in-toggle')) {
            e.preventDefault();
            if ($('#sign-up .sign-modal').hasClass('show')) {
                $('#sign-up .sign-modal').removeClass('show');
                setTimeout(function () {
                    $('#sign-in .sign-modal').addClass('show');
                }, 200);
            } else {
                $('#sign-in .sign-modal').addClass('show');
            }
        } else if ($(this).hasClass('up-toggle')) {
            e.preventDefault();
            if ($('#sign-in .sign-modal').hasClass('show')) {
                $('#sign-in .sign-modal').removeClass('show');
                setTimeout(function () {
                    $('#sign-up .sign-modal').addClass('show');
                }, 200);
            } else {
                $('#sign-up .sign-modal').addClass('show');
            }
        }
    });

    $('.sign-modal .modal-overlay, .close-modal').on('click', function(){
        $('.sign-modal').removeClass('show');
        $('.view-pass').removeClass('active');

        $('.form-response').remove();
    });

    // form custom select

    var fselect = $('.custom-select');

    fselect.on('click', function(){
        $('.custom-select').find('ul').hide();
        $(this).find('ul').show();

        $(this).find('ul li').on('click', function(){
            $(this).parent().parent().find('p').text($(this).attr('data-val')).addClass('color');
            $(this).parent().parent().find('p').attr('data-id', $(this).attr('data-id'));
            $(this).parent().parent().find('.title').addClass('show');
            $(this).parent().fadeOut();
        });
    });

    $(document).mouseup(function (e) { // событие клика по веб-документу
        if (!fselect.is(e.target) // если клик был не по нашему блоку
            && fselect.has(e.target).length === 0) { // и не по его дочерним элементам
            fselect.find('ul').hide(); // скрываем его
        }
    });

    //view password
    $('.view-pass').hover(function(){
        var input = $($(this).attr("toggle"));
        $(this).toggleClass('active');
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    window.pushNotifications = new(function($node) {
        $node = $('[push-notifications]');

        var self = this;
        var notes = $node.find('.notification');
        var template = $node.find('.template');
        var shownNotes = [];
        var maxCount = 4;

        self.bind = function($note) {
            if (!$note.hasClass('shown')) {
                $note.addClass('shown');
            }

            var note = {
                note: $note
            };

            note.timeout = setTimeout(function() {
                self.deleteNotification(note);
            }, parseInt(note.note.attr('expire')) * 1);

            shownNotes.push(note);

            note.note.find('.close').unbind('click').bind('click', function() {
                self.deleteNotification(note);
            });

            if (shownNotes.length > maxCount) {
                var _note = shownNotes[0];

                self.deleteNotification(_note);
            }
        };

        self.deleteNotification = function(note) {
            clearTimeout(note.timeout);
            note.note.removeClass('shown');
            shownNotes.splice(shownNotes.indexOf(note), 1);

            setTimeout(function() {
                note.note.remove();
            }, 450);
        };

        self.init = function() {
            notes.each(function(index, note) {
                self.bind($(note));
            });
        };

        self.push = function(type, icon, text, timeout) {
            var notification = template.clone();

            notification.removeClass('template');
            notification.addClass('notification');
            notification.addClass('notification-' + type);

            notification.removeAttr('hidden');
            notification.attr('expire', timeout);
            notification.find('.text').text(text);
            notification.find('.icon').addClass('mdi mdi-' + icon);

            $node.find('.inner').prepend(notification);

            setTimeout(function() {
                self.bind(notification);
            }, 100);
        };

        self.init();
    })();

    $('[test-notifications]').unbind('click').bind('click', function() {
        var types = ['success', 'danger', 'warning'];
        var icons = ['check-circle-outline', 'close-circle-outline', 'information-outline'];
        var texts = [
            'Lorem ipsum dolor sit amet.',
            'Lorem ipsum dolor sit amet, consectetur.',
            'Lorem ipsum dolor sit amet, consectetur adipisicing.'
        ];

        var type = _.random(0, types.length - 1);

        pushNotifications.push(
            types[type],
            icons[type],
            texts[_.random(0, texts.length - 1)],
            5000
        );
    });

    //delivery check
    $('.delivery-wrapper .delivery-block').on('click', function(){
        if ($(this).hasClass('active')) {

        } else {
            $(this).addClass('active').siblings().removeClass('active');
        }
    });

    function syncOverviewData(pet_id, food_name, quantity, price) {
        let currency = '€';

        function makePrice(price) {
            return currency + ' ' + parseFloat(price).toFixed(2).replace('.', ',');
        }

        $('.overview').find(
            'tr[data-pet-id=' + pet_id + '] .food_name_overview'
        ).html(food_name);

        $('.overview').find(
            'tr[data-pet-id=' + pet_id + '] .food_price_overview'
        ).html(makePrice(quantity * price * 30));

        let total_price = 0;
        $('.food_price_overview').each(function() {
            total_price += parseFloat($(this).text().replace(currency, '').replace(',', '.'));
        });
        total_price += parseFloat($('.delivery_price_cost').html());

        $('.total-price .value').html(makePrice(total_price));
    }

    function syncChosenFoodChoise(context) {
        let pet_id = $(context).data('pet-id'),
            pet_type = $(context).data('pet-type'),
            type = $(context).data('food-key'),
            food_name = $(context).data('food-name'),
            quantity = $(context).data('quantity'),
            food_cat = JSON.parse(prices_food_cat),
            food_dog = JSON.parse(prices_food_dog);

        let price = (pet_type == 'cat') ? food_cat[type] : food_dog[type],
            input_name = "chosen_recommended[" + $(context).data('pet-id') + "]";;

        if ($(context).hasClass('active')) {
            $("[name='" + input_name + "']").val(type);
        } else {
            $("[name='" + input_name + "']").val("");
        }

        syncOverviewData(pet_id, food_name, quantity, price);
    }

    //delivery check
    $('.food-wrapper .food-block').on('click', function(){
        var me = this;

        $(this).closest('.food-wrapper').find('.food-block').each(function() {
            if (this != me) {
                $(this).removeClass('active');
            } else {
                $(this).toggleClass('active');
            }
        });

        syncChosenFoodChoise(me);
    });

    //products input
    $('.products-wrapper .product-block input').on('change', function () {
        if ($(this).val() >= 1) {
            $(this).parent().parent().parent().parent().addClass('active');
        } else {
            $(this).parent().parent().parent().parent().removeClass('active');
        }
    });

    $('#sign-in-form .form-submit').click(function (event) {
        event.preventDefault();

        var $form = $(this).closest('.form');

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true
        });

        return false;
    });

    $('#sign-up-form .form-submit').click(function (event) {
        event.preventDefault();

        var $form = $(this).closest('.form');

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true
        });

        return false;
    });

    $('#account-edit-form .form-submit').click(function (event) {
        event.preventDefault();

        var $form = $(this).closest('.form');

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true
        });

        return false;
    });

    $('#order-form .form-submit').click(function (event) {
        event.preventDefault();

        var $form = $(this).closest('.form');

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true
        });

        return false;
    });

    $('#contact-form .form-submit').click(function (event) {
        event.preventDefault();

        var $form = $(this).closest('form');

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true
        });

        return false;
    });

    $('#sample-product-form .form-submit').click(function (event) {
        event.preventDefault();

        let $form = $(this).closest('.form'),
            choise = $form.find('.choise.active'),
            pet_type = choise.length ? choise.data('pet') : '';

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true,
            data: {
                'pet_type': pet_type
            },
            overrideHandlers: true,
            done: function(response) {
                document.location.href = response.redirect; 
            },
            fail: function() {
                $form.find('.form-error').show();
                $form.find('.form-submit').attr('disabled', false);
            }
        });

        return false;
    });

    $('#sample-product-form .choise').click(function (event) {
        let self = this;

        $('#sample-product-form .choise').each(function () {
            if ($(this).data('pet') != $(self).data('pet')) {
                $(this).removeClass('active');
            }
        });
        
        $(this).toggleClass('active');
    });

    function getTomorrowDate() {
        var today = new Date();
        var tomorrow = new Date();
        
        tomorrow.setDate(today.getDate()+1);
        return tomorrow;
    }

    $(".date").flatpickr({
        minDate: getTomorrowDate()
    });

    $(".select2").select2({
        allowClear: true,
        placeholder: 'select..'
    });

    $(".select2").on("select2:unselecting", function(e) {
        $(this).val(0).change();

        var $title = $(this).closest('.form-group').find('.select-title');

        if ($title.length) {
            $title.hide();
        }
    });

    $(".select2").on("select2:selecting", function(e) { 
        var $title = $(this).closest('.form-group').find('.select-title');

        if ($title.length) {
            $title.show();
        }
    });

    function setActivePayment($logo) {
        if ($logo.closest('.payment').hasClass('inactive')) {
            return;
        }

        var img_src = $logo.attr('src'), new_src = ""; 
        
        if (img_src.indexOf('-active.png') == -1) {
            new_src = img_src.replace('.png', '-active.png');

            $logo.attr('src', new_src);     
        }
        
        $logo.closest('.payment').removeClass('not-hovered');
        $logo.closest('.payment').addClass('hovered');
    }

    function setInactivePayment($logo) {
        if ($logo.closest('.payment').hasClass('inactive')) {
            return;
        }

        var img_src = $logo.attr('src'),
            new_src = img_src.replace('-active.png', '.png');

        $logo.attr('src', new_src);
        
        $logo.closest('.payment').removeClass('hovered');
        $logo.closest('.payment').addClass('not-hovered');
    }

    $(".payment").on("mouseover", function () {
        if (!$(this).closest('.payment').hasClass('active')) {
            setActivePayment($(this).find('.logo img'));
        }
    });

    $(".payment").on("mouseout", function () {
        $(".payment-select .logo img").each(function () {
            var $logo = $(this);

            if (!$logo.closest('.payment').hasClass('active')) {
                setInactivePayment($logo);
            }
        });   
    });

    $(".payment:not(.inactive)").click(function () {
        $(this).toggleClass('active');

        var logoSelector = '.logo img';

        if ($(this).hasClass('active')) {
            $(".payment").each(function () {
                $(this).removeClass('active');

                setInactivePayment($(this).find(logoSelector));
            });

            $(this).addClass('active');
            setActivePayment($(this).find(logoSelector));

            if ($(this).hasClass('ideal')) {
                $('.bank').show();
                $('.bank').addClass('show');
            } else {
                $('.bank').hide();
                $('.bank').removeClass('show');
            }

            $("[name='payment_method']").val($(this).data('type'));
        } else {
            setInactivePayment($(this).find(logoSelector));
            $('.bank').hide();

            $("[name='payment_method']").val("");
        }
    });

    $("#to_another_address").change(function () {
        $('.diff-address-fields').toggle();
    });
});