let PurchaseService = function(ApiRequest) {
    let uriPrefix = '/products';

    return new (function() {
        this.index = function(data) {
            return ApiRequest.get(
                uriPrefix, data
            );
        };
        
        this.store = function(values) {
            values = this.apiFormToResource(values);

            return ApiRequest.post(
                uriPrefix, values
            );
        };

        this.apiFormToResource = function(formData) {
            return {};
        };
    });
};

module.exports = [
    'ApiRequest',
    PurchaseService
];