let PetService = function(
    $filter,
) {
    let trans = (key, prefix = 'purchasing.') => {
        return $filter('translate')(prefix + key);
    };

    function Pet(
        pet_type = 'dog', 
        name = null, 
        age = 0, 
        weight = null, 
        castrated = 'yes', 
        activity = 'quiet',
        //taste = 'salmon',
        taste = 'no'
    ) {
        this.pet_type = pet_type;
        this.name = name;
        this.age = age;
        this.weight = weight;
        this.initialWeight = 1.5;
        this.weightShown = weight;
        this.castrated = castrated;
        this.activity = activity;
        this.preferred_taste = taste;
    }

    return new (function() {
        this.steps = [{
                'name': trans('sidebar_pet_type'),
            }, {
                'name': trans('sidebar_pet_nr'),
            }, {
                'name': trans('sidebar_pet_name'),
            }, {
                'name': trans('sidebar_pet_age'),
            }, {
                'name': trans('sidebar_pet_weight'),
            }, {
                'name': trans('sidebar_pet_castrated'),
            }, {
                'name': trans('sidebar_pet_activity_type'),
            }
            // , {
            //     'name': trans('sidebar_pet_taste_pref'),
            // }
        ];

        this.initialSteps = angular.copy(this.steps);
        this.countSteps = this.steps.length;
        this.showDogs = true;
        this.showCats = false;

        this.getPetInstance = function(pet_type, name, age) {
            return new Pet(pet_type, name, age);
        }

        this.setPetAttrOption = function(
            $event, newValue, petType, petIndex, petAttribute
        ) {
            let currValue = this.form.values[petType][petIndex][petAttribute];

            this.form.values[petType][petIndex][petAttribute] = 
                (currValue != newValue) ? newValue : null;
        }

        this.selectPetType = function($event, value) {
            this.form.values.pet_type = this.form.values.pet_type != value ? value : null;

            if (value == 'dog') {
                this.form.values.cats = [];
            } else {
                this.form.values.dogs = [];
            }
        }

        this.isPositive = function(value, dir, step = 1) {
            if (Number(value) <= step && dir.toLowerCase() == 'desc') {
                return false;
            } 

            return true;
        }
    
        this.changePetAttrByStep = function(petType, petIndex, petAttribute, dir, step = 1) {
            let currValue = Number(this.form.values[petType][petIndex][petAttribute]);

            if (!this.isPositive(currValue, dir, step)) {
                return;
            }
            
            let newValue = currValue + ((dir.toLowerCase() == 'asc') ? step : (-step)); 

            this.form.values[petType][petIndex][petAttribute] = 
                (currValue != newValue) ? newValue : null;
        }

        this.changeWeightAttr = function(petType, petIndex, dir) {
            let pet = this.form.values[petType][petIndex];

            if (pet.weight == null) {
                pet.weight = pet.initialWeight;
            }

            this.changePetAttrByStep(petType, petIndex, 'weight', dir, 0.5);
        }

        this.changeCatNr = function(dir) {
            let currValue = Number(this.form.values.quantity_cat);

            if (!this.isPositive(currValue, dir)) {
                return;
            }

            let newValue = currValue + ((dir.toLowerCase() == 'asc') ? 1 : (-1)); 

            this.form.values.quantity_cat = newValue;
        }

        this.changeDogNr = function(dir) {
            let currValue = Number(this.form.values.quantity_dog);

            if (!this.isPositive(currValue, dir)) {
                return;
            }

            let newValue = currValue + ((dir.toLowerCase() == 'asc') ? 1 : (-1)); 

            this.form.values.quantity_dog = newValue;    
        }

        this.changePetNr = function() {   
            //Change Cat Nr
            this.form.values.cats.length = this.form.values.quantity_cat;

            for (let i = 0; i < this.form.values.cats.length; i++) {
                if (typeof this.form.values.cats[i] == 'undefined') {
                    this.form.values.cats[i] = new Pet('cat');
                }
            }

            this.form.values.dogs.length = this.form.values.quantity_dog;

            //Change Dog Nr
            for (let i = 0; i < this.form.values.dogs.length; i++) {
                if (typeof this.form.values.dogs[i] == 'undefined') {
                    this.form.values.dogs[i] = new Pet('dog');
                }
            }
        }

        // this.syncWeight = function(pet) {
        //     let weight = parseFloat(pet.weight);
            
        //     pet.weightShown = weight + ' kg';
        // }

        this.onlyIntNumbersForAttr = function (pet, attr) {
            pet[attr] = parseInt(pet[attr]);
        }

        this.onlyIntNumbers = function (attr) {
            this.form.values[attr] = parseInt(this.form.values[attr]);
        }

        this.onlyNumbersForAttr = function (pet, attr) {
            if (pet[attr].indexOf('.') == pet[attr].length - 1) {
                return;
            }

            pet[attr] = parseFloat(pet[attr]);
        }

        this.setOptions = function() {
            this.animalTypes = {
                'dog':  trans('pet_type_dog'),
                'cat':  trans('pet_type_cat'),
                'both': trans('pet_type_both'),
            };
    
            this.yearOptions = {
                0: '0 – 1', 
                1: '1 - 4', 
                4: '4 - 8',
                8: '8+' 
            };
    
            this.castratedOptions = {
                'yes': trans('pet_castrated_yes'),
                'no': trans('pet_castrated_no'),
            };
    
            this.activityOptions = {
                'quiet': trans('pet_activity_quiet'),
                'average': trans('pet_activity_normal'),
                'active': trans('pet_activity_active')
            };
    
            this.tasteOptions = {
                'salmon': trans('pet_taste_salmon'),
                'chicken': trans('pet_taste_chicken'),
                'lamb': trans('pet_taste_lamb'),
                'no': trans('pet_taste_no')
            };
        }
    });
};

module.exports = [
    '$filter',
    PetService
];