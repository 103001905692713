let ProductComponent = function(
    FormBuilderService,
    ProductService
) {
    let $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.step = 1;

        $ctrl.form = FormBuilderService.build({
            
        }, (form) => {
            form.lock();

            console.log(form.values);

            PurchaseService.calculate(
                form.values
            ).then(res => {
                console.log('success: ', res);
            }, res => {
                console.log('errors: ', res);
            });
        });
    };

    $ctrl.$onDestroy = function() { };
};

module.exports = {
    controller: [
        'FormBuilderService',
        'ProductService',
        ProductComponent
    ],
    templateUrl: () => {
        return '/assets/tpl/pages/product.html';
    }
};