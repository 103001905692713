let PurchaseService = function(
    ApiRequest
) {
    let uriPrefix = '/subscriptions';

    return new (function() {
        this.index = function(data) {
            return ApiRequest.get(
                uriPrefix, data
            );
        };
        
        this.getData = function() {
            return ApiRequest.post(
                uriPrefix + '/info'
            );
        };
        
        this.calculate = function(values) {
            values = this.apiFormToResource(values);

            return ApiRequest.post(
                uriPrefix + '/calculate', 
                values
            );
        };

        this.apiFormToResource = function(formData) {
            let pets = formData.dogs.concat(formData.cats);

            pets.forEach(function(pet) {
                pet.is_castrated = pet.castrated == 'yes' ? true : false;
                pet.pet_activity_type = pet.activity;
            });

            return {
                pets: pets
            };
        };

        this.apiResourceToForm = function(data) {
            let pets = data.data.pets,
                result = {
                    pet_type: null,
                    quantity_cat: 0,
                    quantity_dog: 0,
                    showCats: false,
                    showDogs: false,
                    cats: [],
                    dogs: [],
                };

            pets.forEach(function (pet) {
                pet.castrated = pet.is_castrated ? 'yes' : 'no';
                pet.activity = pet.pet_activity_type;

                if (pet.pet_type == 'cat') {
                    result.cats.push(pet);
                } else {
                    result.dogs.push(pet);
                }
            });

            if (!pets.length) {
                return {
                    pet_type: 'dog',
                    quantity_cat: 0,
                    quantity_dog: 0,
                    cats: [],
                    dogs: []
                };
            } else {
                result.pet_type = 'both';

                if (result.cats.length && !result.dogs.length) {
                    result.pet_type = 'cat';
                    result.showCats = true;
                }

                if (result.dogs.length && !result.cats.length) {
                    result.pet_type = 'dog';
                    result.showDogs = true;
                }

                result.quantity_cat = result.cats.length;
                result.quantity_dog = result.dogs.length;

                return result;
            }
        }
    });
};

module.exports = [
    'ApiRequest',
    PurchaseService
];