module.exports = ['$translateProvider', 'appConfigs', function($translateProvider, appConfigs) {
    $translateProvider.translations('nl', appConfigs.i18nTranslations.nl || {});
    // $translateProvider.translations('en', appConfigs.i18nTranslations.en || {});
    // $translateProvider.translations('fr', appConfigs.i18nTranslations.fr || {});
    // $translateProvider.translations('de', appConfigs.i18nTranslations.de || {});
    
    // $translateProvider.registerAvailableLanguageKeys([
    //     'en', 'nl', 'fr', 'de'
    // ]);

    $translateProvider.registerAvailableLanguageKeys([
        'nl'
    ]);

    $translateProvider.useSanitizeValueStrategy('escapeParameters');
    $translateProvider.useLocalStorage();
    $translateProvider.preferredLanguage(lang);
    $translateProvider.fallbackLanguage('nl');
}];